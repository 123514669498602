import {
	iconEye,
	iconEyeSlash
} from './_icons';

/**
 * Adds a wrapper around the field and adds the eye icon to toggle the visibility of the content
 */
export default class PasswordField {
    /**
     * Adds a wrapper around the field and adds the eye icon to toggle the visibility of the content
     * @param {Node} $field The Node of the password
     */
    constructor( $field ) {
        // Check if the field exists
        if ( ! $field ) {
            return;
        }

        // Check if this isn't already inside a wrapper
        if ( $field.closest( `.${ this.cssClasses.passwordWrapper }` ) ) {
            return;
        }

        // Save Node
        this.$field = $field;

        // Add wrapper
        this.addWrapper();

        // Add content visibility toggle
        this.contentVisibilityToggle();

        // Style focus on wrapper
        this.styleFocusOnWrapper();
    }

    /**
     * Add wrapper around password field
     */
    addWrapper() {
        // Create wrapper
		this.$passwordWrapper = document.createElement( 'label' );
		this.$passwordWrapper.classList.add( this.cssClasses.passwordWrapper );

		// Add the wrapper next to the password field
		this.$field.insertAdjacentElement( 'afterEnd', this.$passwordWrapper );

		// Move the Password field inside the wrapper
		this.$passwordWrapper.appendChild( this.$field );
    }

    /**
     * Adds an eye icon that sets the visibility of the content
     */
    contentVisibilityToggle() {
        // Add eye to set the visibility of the password
		this.$passwordWrapper.insertAdjacentHTML( 'beforeEnd', `
            <div class="${ this.cssClasses.passwordVisibilityToggle }">
                ${ iconEye }
            </div>
        ` );

        // Get the eye icon wrapper
        const $passwordVisibilityToggle = this.$passwordWrapper.querySelector( `.${ this.cssClasses.passwordVisibilityToggle }` );

        // Add event to the visibility toggle
        $passwordVisibilityToggle.addEventListener( 'click', () => {
            // Get the current status of the field
            const passwordIsVisible = this.$field.getAttribute( 'type' ) == 'text';

            // If it's visible, and the user clicked it, it means we should hide it
            if ( passwordIsVisible ) {
                // Hide the password
                this.$field.setAttribute( 'type', 'password' );

                // Replace eye icon. Show icon without the slash
                $passwordVisibilityToggle.innerHTML = iconEye;
            }

            // If it's hidden, and the user clicked it, it means we should show it
            if ( ! passwordIsVisible ) {
                // Show the password
                this.$field.setAttribute( 'type', 'text' );

                // Replace eye icon. Add icon with the slash
                $passwordVisibilityToggle.innerHTML = iconEyeSlash;
            }
        } );
    }

    /**
     * Listens to the focus state of the field, and adds a class to the wrapper to style it as if it were a field
     */
    styleFocusOnWrapper() {
        // Listen focus on field
        this.$field.addEventListener( 'focus', () => {
            // Add focus class
            this.$passwordWrapper.classList.add( `${ this.cssClasses.passwordWrapper }--focus` );
        } );

        // Remove focus class
        this.$field.addEventListener( 'blur', () => {
            this.$passwordWrapper.classList.remove( `${ this.cssClasses.passwordWrapper }--focus` );
        } );
    }

    /**
     * Object with the CSS classes used by the wrapper
     */
    get cssClasses() {
        return {
            passwordWrapper: 'ult-form-password-wrapper',
			passwordVisibilityToggle: 'ult-form-password-wrapper-eye-toggle'
        }
    }
}